<script>
export default {
  name: "Alert",

  data: () => ({
    config: {
      warning: {
        color: "warning",
        icon: "mdi-alert-circle",
      },
      success: {
        color: "success",
        icon: "mdi-checkbox-marked-circle",
      },
      error: {
        color: "error",
        icon: "mdi-alert",
      },
      info: {
        color: "primary",
        icon: "mdi-information",
      },
    },
  }),

  computed: {
    show() {
      return this.$store.getters["alert/show"];
    },
    messages() {
      return this.$store.getters["alert/messages"];
    },
  },

  methods: {
    clickHandler() {
      this.hide();
    },
    hide(index) {
      this.$store.dispatch("alert/hide", index);
    },
  },
};
</script>

<template>
  <div class="alert-container">
    <transition-group name="list-complete" tag="p">
      <div
        v-for="(message, index) in messages"
        :key="message.index"
        class="mb-5 list-complete-item"
      >
        <div class="alert">
          <v-alert
            :color="config[message.type].color"
            border="left"
            class="mb-0 pr-2"
            colored-border
            min-width="414"
            max-width="825"
          >
            <div class="d-flex align-start">
              <v-icon :color="config[message.type].color">
                {{ config[message.type].icon }}
              </v-icon>
              <div v-if="message.list && message.list.length">
                <div class="subtitle-1 pl-3">{{ message.title }}</div>
                <v-row class="ml-2 mt-4">
                  <v-col
                    :class="{ 'col-md-6': message.list.length > 10 }"
                    cols="12"
                    v-for="(listItem, itemIndex) in message.list"
                    :key="itemIndex"
                    class="body-1 py-0"
                  >
                    - {{ listItem }}
                  </v-col>
                </v-row>
              </div>
              <span
                v-else
                @click="clickHandler(message, index)"
                class="dark_blue--text pl-3"
                :class="{ 'cursor-pointer': message.onClick }"
                >{{ message.text || "Неизвестная ошибка сервера" }}</span
              >
              <v-spacer></v-spacer>
              <v-icon @click="hide(index)"> mdi-close </v-icon>
            </div>
          </v-alert>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<style lang="scss" scoped>
.alert-container {
  position: fixed;
  bottom: 118px;
  right: 2px;
  z-index: 9999;
}

.alert {
  box-shadow: 0 11px 15px rgba(0, 0, 0, 0.2);
}

.list-complete-item {
  transition: all 0.4s;
  margin-bottom: 20px;
}

.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
  transform: translateX(200px);
}

.list-complete-leave-active {
  position: relative;
}
</style>
